import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 23" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 23</h1>
      <p>God morgen mamma!</p>
      <p>Hjelp, nå har julestresset ankommet meg! Jeg har klart å rote bort en av dine sminketing, så i dag blir det kun en ekstragave fra meg, ikke sminketing.</p>
      <p>Jeg tenkte at et par gulpekluter ekstra kommer godt med, siden jeg skal proppe i meg massevis med julemelk i morgen på JULAFTEN!!!!!</p>
      <p>Jeg lover å sove godt i kveld, og kanskje i morgen! Tihihihihiih!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke
